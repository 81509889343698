import * as React from 'react';
import {useEffect, useState} from 'react';
import {getScreenInformation, getScreenZonesOccupancies} from '../server';
import {CarouselLoaderConfig, ScreenInfoState, ZoneOccupancy} from '../types';
import {Spinner} from '@autopay.io/style';
import {ErrorScreen} from "../../common/components/ErrorScreen";
import {
    dataFetchIntervalInMs,
    LAST_SUCCESSFUL_DATA_FETCH,
    occupancyFetchIntervalInMs,
    twoHoursInMs
} from "../constants";
import '@autopay.io/screen-style/lib/main.css';
import {runEveryMidnight, runTenSecondsAfterEveryFullHour} from "../../util/utils";
import {ZonesScreen} from '@autopay.io/screen-style';

interface ZonesScreenIndexProps {
    screenId: string;
    carouselLoaderConfig?: CarouselLoaderConfig;
}

export const ZonesScreenIndex = ({screenId, carouselLoaderConfig}: ZonesScreenIndexProps) => {

    const [screenInfo, setScreenInfo] = useState<ScreenInfoState>(null);
    const [zoneOccupancies, setZoneOccupancies] = useState<ZoneOccupancy[] | null>(null);

    useEffect(() => {
        getScreenInfo(screenId)
        setInterval(() => getScreenInfo(screenId), dataFetchIntervalInMs)
        runTenSecondsAfterEveryFullHour(() => getScreenInfo(screenId))
        runEveryMidnight(() => location.reload())
    }, [screenId]);

    useEffect(() => {
        if (screenInfo?.type === 'DATA' && screenInfo.screen.zonesDisplayContentType === 'OCCUPANCY') {
            getOccupancyInfo(screenId)
            const occupancyTimer = setInterval(() => getOccupancyInfo(screenId), occupancyFetchIntervalInMs)
            return () => clearInterval(occupancyTimer);
        }
    }, [screenInfo]);

    const getScreenInfo = (id: string) => {
        getScreenInformation(id).then((r) => {
            const lastSuccessfulDataFetch = localStorage.getItem(LAST_SUCCESSFUL_DATA_FETCH);
            const now = new Date().getTime();
            if (r !== 'ERROR' && r !== 'NOT_FOUND') {
                setScreenInfo({type: 'DATA', screen: r});
                localStorage.setItem(LAST_SUCCESSFUL_DATA_FETCH, Date.now().toString());
            } else if (r === 'NOT_FOUND') {
                setScreenInfo({type: 'ERROR', code: null, message: 'Screen not found'});
            } else if (r === 'ERROR' && lastSuccessfulDataFetch !== null && now - Number(lastSuccessfulDataFetch) > twoHoursInMs) {
                setScreenInfo({type: 'ERROR', code: null, message: 'Data not found'});
            }
        });
    }

    const getOccupancyInfo = (id: string) => {
        getScreenZonesOccupancies(screenId).then((r) => {
            if (r !== 'ERROR' && r !== 'NOT_FOUND') {
                setZoneOccupancies(r);
            }
        })
    }

    if (screenInfo === null || (screenInfo?.type === 'DATA' && screenInfo.screen.zonesDisplayContentType === 'OCCUPANCY' && zoneOccupancies === null)) {
        return <Spinner size={'md'} />;
    } else if (screenInfo.type === 'ERROR') {
        return <ErrorScreen text={screenInfo.message}/>;
    } else {
        return <ZonesScreen screenInfo={screenInfo.screen} zoneOccupancies={zoneOccupancies!!} carouselLoaderConfig={carouselLoaderConfig}/>
    }
};
