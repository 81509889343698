import * as React from 'react';
import {useEffect, useState} from 'react';
import {getScreenInformation} from '../server';
import {CarouselLoaderConfig, ScreenInfoState} from '../types';
import {Spinner} from '@autopay.io/style';
import './price.less';
import {ErrorScreen} from "../../common/components/ErrorScreen";
import {dataFetchIntervalInMs, LAST_SUCCESSFUL_DATA_FETCH, twoHoursInMs} from "../constants";
import {PriceDisplayScreen} from '@autopay.io/screen-style';
import '@autopay.io/screen-style/lib/main.css';
import {runEveryMidnight, runTenSecondsAfterEveryFullHour} from "../../util/utils";

interface PriceScreenIndexProps {
    screenId: string;
    carouselLoaderConfig?: CarouselLoaderConfig;
}

export const PriceScreenIndex = ({screenId, carouselLoaderConfig}: PriceScreenIndexProps) => {

    const [screenInfo, setScreenInfo] = useState<ScreenInfoState>(null);

    useEffect(() => {
        getInfo(screenId)
        setInterval(() => getInfo(screenId), dataFetchIntervalInMs)
        runTenSecondsAfterEveryFullHour(() => getInfo(screenId))
        runEveryMidnight(() => location.reload())
    }, [screenId]);

    const getInfo = (id: string) => {
        getScreenInformation(id).then((r) => {
            const lastSuccessfulDataFetch = localStorage.getItem(LAST_SUCCESSFUL_DATA_FETCH);
            const now = new Date().getTime();
            if (r !== 'ERROR' && r !== 'NOT_FOUND') {
                setScreenInfo({type: 'DATA', screen: r});
                localStorage.setItem(LAST_SUCCESSFUL_DATA_FETCH, Date.now().toString());
            } else if (r === 'NOT_FOUND') {
                setScreenInfo({type: 'ERROR', code: null, message: 'Screen not found'});
            } else if (r === 'ERROR' && lastSuccessfulDataFetch !== null && now - Number(lastSuccessfulDataFetch) > twoHoursInMs) {
                setScreenInfo({type: 'ERROR', code: null, message: 'Data not found'});
            }
        });
    }

    if (screenInfo === null) {
        return <Spinner size={'md'} />;
    } else if (screenInfo.type === 'ERROR') {
        return <ErrorScreen text={screenInfo.message}/>;
    } else {
        return <PriceDisplayScreen screenInfo={screenInfo.screen} carouselLoaderConfig={carouselLoaderConfig}/>
    }
};
